import React, { createContext, useState, useEffect } from "react"
import Client from "shopify-buy"

const shopClient = Client.buildClient({
  domain: "adam-monster.myshopify.com",
  storefrontAccessToken: "a75a894d6011c41902296fab46f97745",
})

export const StoreContext = createContext({
  isUpdating: false,
  checkoutData: { lineItems: [] },
  products: [],
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
})

export const StoreProvider = ({ children }) => {
  const [isUpdating, setUpdating] = useState(false)
  const [checkoutData, setCheckoutData] = useState({ lineItems: [] })

  const initCheckout = async () => {
    const isBrowser = typeof window !== "undefined"
    const existingCheckoutId = isBrowser
      ? localStorage.getItem("shopify_checkout_id")
      : null
    const createNewCheckout = () => shopClient.checkout.create()
    const fetchCheckout = (id) => shopClient.checkout.fetch(id)
    const setCheckoutInState = (c) => {
      if (isBrowser) {
        localStorage.setItem("shopify_checkout_id", c.id)
      }

      setCheckoutData(c)
    }

    if (existingCheckoutId) {
      try {
        const fetchedCheckout = await fetchCheckout(existingCheckoutId)

        if (!fetchedCheckout.completedAt) {
          setCheckoutInState(fetchedCheckout)
          return
        }
      } catch (e) {
        localStorage.setItem("shopify_checkout_id", null)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  }

  const handleAddVariantToCart = (variantId, quantity) => {
    if (variantId === "" || !quantity) {
      // eslint-disable-next-line no-console
      console.error("Both a size and quantity are required.")
      return
    }

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

    setUpdating(true)

    // eslint-disable-next-line consistent-return
    return shopClient.checkout
      .addLineItems(checkoutData.id, lineItemsToAdd)
      .then((res) => {
        setCheckoutData(res)
        setUpdating(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  const handleRemoveLineItem = async (lineItemId) => {
    setUpdating(true)
    return shopClient.checkout
      .removeLineItems(checkoutData.id, [lineItemId])
      .then((res) => {
        setUpdating(false)
        setCheckoutData(res)
      })
  }

  const handleUpdateLineItem = async (lineItemId, quantity) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    setUpdating(true)

    return shopClient.checkout
      .updateLineItems(checkoutData.id, lineItemsToUpdate)
      .then((res) => {
        setUpdating(false)
        setCheckoutData(res)
      })
  }

  useEffect(() => {
    initCheckout()
  }, [])

  return (
    <StoreContext.Provider
      value={{
        isUpdating,
        checkoutData,
        addVariantToCart: handleAddVariantToCart,
        removeLineItem: handleRemoveLineItem,
        updateLineItem: handleUpdateLineItem,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContext
