/* eslint-disable import/prefer-default-export */
import React from "react"

import { InterfaceProvider } from "./src/contexts/InterfaceContext"
import { StoreProvider } from "./src/contexts/StoreContext"

export const wrapRootElement = ({ element }) => (
  <InterfaceProvider>
    <StoreProvider>{element}</StoreProvider>
  </InterfaceProvider>
)
