import React, { useState, createContext } from "react"

export const InterfaceContext = createContext({
  isCartOpen: false,
  isNavOpen: false,
  toggleCart: () => {},
  toggleNav: () => {},
})

export const InterfaceProvider = ({ children }) => {
  const [isCartOpen, setCartOpen] = useState(false)
  const [isNavOpen, setNavOpen] = useState(false)

  return (
    <InterfaceContext.Provider
      value={{
        isCartOpen,
        isNavOpen,
        toggleCart: () => setCartOpen(!isCartOpen),
        toggleNav: () => setNavOpen(!isNavOpen),
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
